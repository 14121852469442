import { getRoot, Instance, types } from 'mobx-state-tree'

import { Notification } from '@store/models/Notification'
import { NotificationType } from '@constants/types'
import { checkInternetConnectivity } from '@utils/checkConnectivity'
import { IRootStore } from '@store/RootStore'

const NotificationsStore = types
    .model('NotificationsStore', {
        notifications: types.array(Notification),
    })
    .views(self => {
        return {
            get notification() {
                return self.notifications[0]
            },
            get count() {
                return self.notifications.length
            },
            get root(): IRootStore {
                return getRoot(self)
            },
        }
    })
    .actions(self => {
        return {
            addNotification(type: NotificationType, message: string, index?: number) {
                if (type !== NotificationType.SUCCESS) {
                    checkInternetConnectivity(self.root.setConnectionStatus)
                }

                const notification = Notification.create({
                    message,
                    type,
                })

                if (index) {
                    self.notifications.splice(index, 0, notification)
                    return
                }

                self.notifications.push(notification)
            },
            dismissCurrentNotification: () => {
                self.notifications.shift()
            },
        }
    })

export default NotificationsStore

export interface INotificationsStore extends Instance<typeof NotificationsStore> {}
