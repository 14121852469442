import { NotificationType } from '@constants/types'
import { Instance, types } from 'mobx-state-tree'

export const Notification = types
    .model('Notification', {
        message: types.string,
        type: typeof NotificationType,
    })
    .views(self => {
        return {
            get isNotice() {
                return self.type === NotificationType.SUCCESS
            },
            get isWarning() {
                return self.type === NotificationType.WARNING
            },
            get isError() {
                return self.type === NotificationType.ERROR
            },
        }
    })

export interface INotification extends Instance<typeof Notification> {}
