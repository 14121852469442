import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react'
import ReactDOM from 'react-dom/client'
import { Provider, observer } from 'mobx-react'
import rootStore, { AppStoreContext, ConnectionStatus, LoadingState, IRootStore } from '@store/RootStore'
import Home from '@components/pages/Home'
import { registerSW } from 'virtual:pwa-register'
import * as Sentry from '@sentry/react'
// @ts-ignore not relevant to have the types
import { install } from 'ga-gtag'

import TagManager from 'react-gtm-module'

import ENV from '@constants/env'
// TODO: check if this is correct:
import '@styles/globals.scss'
import ProgressAnimation from '@components/intro-animation/progress-animation'
import { LoginState } from '@store/AuthStore'
import { LoginFailedMessage } from '@components/pages/LoginFailedMessage'
import { IntroAnimation } from '@components/intro-animation/intro-animation'
import { LS_HIDE_INTRO } from '@constants/storage'
import { Offline } from '@components/pages/Offline'
import { useFetch } from '@src/hooks/useFetch'
import { formatGTMEvent, GTMEvent, IInitAppEvent } from '@utils/gtm-event'
import { DeviceIdCreator } from '@utils/device-id-creator'
import { OverlayScrollbarsComponent } from 'overlayscrollbars-react'

const intervalMS = 60 * 60 * 1000 * 12

registerSW({
    immediate: true,
    onRegisteredSW(swUrl: string, registration: ServiceWorkerRegistration | undefined) {
        // eslint-disable-next-line no-console
        console.info('[PWA] Registered SW', swUrl)

        if (registration) {
            setInterval(async () => {
                if (!(!registration.installing && navigator)) {
                    console.warn('[PWA] No registration or navigator')

                    return
                }

                if ('connection' in navigator && !navigator.onLine) {
                    console.warn('[PWA] No connection')

                    return
                }

                const resp = await fetch(swUrl, {
                    cache: 'no-store',
                    headers: {
                        cache: 'no-store',
                        'cache-control': 'no-cache',
                    },
                })

                if (resp?.status === 200) {
                    // eslint-disable-next-line no-console
                    console.info('[PWA] New version available')
                    await registration.update()
                }
            }, intervalMS)
        }
    },
})

enum Views {
    INTRO,
    HOME,
}

let isSentryInitialized = false

const App = observer(() => {
    const [view, setView] = useState<Views | null>(null)
    const { fetcher } = useFetch()
    const appStore: IRootStore = useContext(AppStoreContext)
    const {
        auth: authStore,
        gtmInitialized,
        setGTMInitialized,
        setConnectionStatus,
        connectionStatus,
        loadingState,
        loadApp,
        checkForUpdate,
        isAppLoaded,
        setFetcher,
        fetcher: storeFetcher,
    } = appStore

    if (!storeFetcher) {
        setFetcher(fetcher)
    }

    const setupIntegrations = useCallback(async () => {
        if (ENV.PROD) {
            if (!isSentryInitialized) {
                // eslint-disable-next-line no-console
                console.log('Initializing Sentry')
                Sentry.init({
                    dsn: 'https://19d9c91e104fa6bfcb7ff99f3957f362@o1373866.ingest.us.sentry.io/4507028175978496',
                    integrations: [
                        Sentry.captureConsoleIntegration({
                            levels: ['error'],
                        }),
                        Sentry.browserTracingIntegration(),
                        Sentry.replayIntegration({
                            maskAllText: false,
                            blockAllMedia: false,
                        }),
                    ],
                    // Performance Monitoring
                    tracesSampleRate: 1.0, //  Capture 100% of the transactions
                    // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
                    //tracePropagationTargets: ['localhost:4173', 'localhost:5173', /^https:\/\/plugins.*\.com/],
                    // Session Replay
                    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
                    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
                })
                isSentryInitialized = true
            }

            install(ENV.VITE_GOOGLE_ANALYTICS)

            // Init the GTM with the custom device id and log level
            if (ENV.VITE_GOOGLE_TAG_MANAGER && !gtmInitialized) {
                try {
                    const deviceIdCreator = new DeviceIdCreator()
                    // Generate the device id, which is used in Amplitude Initialisation
                    deviceIdCreator.createDeviceId().then((deviceId: string | null) => {
                        if (!deviceId) return

                        // Send the init event to GTM, to set the deviceId and environment
                        const gtmInitArguments = formatGTMEvent(GTMEvent.INIT, {
                            deviceId,
                            logLevel: Number(ENV.VITE_AMPLITUDE_LOG_LEVEL) ?? 0,
                        } as IInitAppEvent)

                        TagManager.initialize(gtmInitArguments)
                        setGTMInitialized(true)
                    })
                } catch (e) {
                    console.error('Error while generating device id', e)
                }
            }
        } else {
            // eslint-disable-next-line no-console
            console.log('Sentry not initialized')
        }
    }, [])

    useEffect(() => {
        const load = async () => {
            // Setup GTM and Sentry
            setupIntegrations()

            // Do steps to load the app
            await loadApp()
        }

        // load the app first time
        load()
    }, [])

    useEffect(() => {
        // Check for update if the app is loaded
        if (loadingState !== LoadingState.LOADED) return

        checkForUpdate(true)
    }, [loadingState])

    // Only show the intro animation if it's the user's first time
    useEffect(() => {
        const hasSeenIntro = localStorage.getItem(LS_HIDE_INTRO)
        setView(hasSeenIntro === 'true' ? Views.HOME : Views.INTRO)
        const setOfflineStatus = () => {
            setConnectionStatus(ConnectionStatus.OFFLINE)
        }

        const setOnlineStatus = () => {
            setConnectionStatus(ConnectionStatus.ONLINE)
        }

        if (typeof window !== 'undefined') {
            window.addEventListener('offline', setOfflineStatus)
            window.addEventListener('online', setOnlineStatus)
        }

        return () => {
            if (typeof window !== 'undefined') {
                window.removeEventListener('offline', setOfflineStatus)
                window.removeEventListener('online', setOnlineStatus)
            }
        }
    }, [])

    const dismissIntroAnimation = useCallback(() => {
        localStorage.setItem(LS_HIDE_INTRO, 'true')
        setView(Views.HOME)
    }, [])

    const showProgressAnimation = useMemo(() => {
        if (authStore.state !== LoginState.loggedIn && authStore.state !== LoginState.errored) return true
        return authStore.state === LoginState.loggedIn && !isAppLoaded
    }, [authStore.state, isAppLoaded])

    return (
        <OverlayScrollbarsComponent element="section" className="w-screen h-screen" defer>
            {showProgressAnimation && <ProgressAnimation />}
            {authStore.state === LoginState.loggedIn && view === Views.HOME && isAppLoaded && <Home />}
            {authStore.state === LoginState.loggedIn && view === Views.INTRO && <IntroAnimation dismissCallback={dismissIntroAnimation} />}
            {authStore.state !== LoginState.loggedIn && authStore.state === LoginState.errored && <LoginFailedMessage />}
            {connectionStatus === ConnectionStatus.OFFLINE && <Offline />}
        </OverlayScrollbarsComponent>
    )
})

// get query parameters
const queryString = window.location.search
const urlParams = new URLSearchParams(queryString)
const connectivityCheck = urlParams.get('connectivityCheck')

if (connectivityCheck === 'true') {
    // connectivity check,
    // if we are here, we have connectivity
    if (typeof (globalThis as any).__postNativeMessage__ === 'function') {
        ;(globalThis as any).__postNativeMessage__('connectivityCheck', 'true')
    }
} else {
    ReactDOM.createRoot(document.getElementById('root') as any).render(
        <Provider store={rootStore}>
            <React.StrictMode>
                <App />
            </React.StrictMode>
        </Provider>,
    )
}
