import { useRef } from 'react'
import { useVirtualizer } from '@tanstack/react-virtual'

import PluginRow from '@components/feed/PluginRow'
import { IFLPlugin } from '@store/models/FLPlugin'
import { OverlayScrollbarsComponent } from 'overlayscrollbars-react'
import { PLUGIN_ROW_HEIGHT } from '@constants/dimensions'

type IInfiniteScrollerProps = {
    items: IFLPlugin[]
    isLoading: boolean
    fetchItems: (page: number, limit?: number) => void
    scale: number
}

const InfiniteScroller = ({ items, isLoading, scale = 1 }: IInfiniteScrollerProps) => {
    const parentRef = useRef<HTMLDivElement>(null)
    const virtualizer = useVirtualizer({
        count: items.length,
        getScrollElement: () => {
            return parentRef.current
        },
        estimateSize: () => {
            return PLUGIN_ROW_HEIGHT * scale
        },
        overscan: 1,
    })
    const virtualItems = virtualizer.getVirtualItems()

    return (
        <>
            <OverlayScrollbarsComponent element="section" className="max-h-full h-full max-w-[calc(100vw-8.5rem)] pr-14px -mr-10px" defer>
                <div
                    ref={parentRef}
                    className="relative w-full"
                    style={{
                        height: virtualizer.getTotalSize(),
                    }}>
                    <div
                        className="absolute top-0 left-0 w-full -translate-y-8px"
                        style={{
                            transform: `translateY(${virtualItems[0]?.start ?? 0}px)`,
                        }}>
                        {virtualItems.map((virtualRow: any) => {
                            return (
                                <div key={virtualRow.key} data-index={virtualRow.index} ref={virtualizer.measureElement}>
                                    {/** TODO add types **/}
                                    <PluginRow plugin={items[virtualRow.index] as any} />
                                </div>
                            )
                        })}
                    </div>
                </div>
                {isLoading && 'Loading...'}
            </OverlayScrollbarsComponent>
        </>
    )
}

export default InfiniteScroller
