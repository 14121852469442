import { useContext, useEffect } from 'react'
import { AppStoreContext } from '@store/RootStore'
import { checkInternetConnectivity } from '@utils/checkConnectivity'
import { getCookie, isTTLCookieExpired } from '@utils/cookie'
import ENV from '@constants/env'
import { SESSION_COOKIE_TTL } from '@constants/cookies'

export interface IFetcher {
    /** Method of the fetcher (i.e. 'POST', default 'GET') */
    method?: string
    /** Boolean parameter to prevent a call if user is not logged in, default false  */
    requiredLogin?: boolean
    /** Boolean parameter to show success messages of response, default false  */
    displaySuccessNotification?: boolean
    /** Boolean parameter to show error messages of response, default true  */
    displayErrorNotification?: boolean
    /** Credentials of request, default 'include  */
    credentials?: 'include' | 'omit' | 'same-origin'
    /** Boolean parameter, true if the response contain blob data, default false  */
    blobType?: boolean
    /** Headers of fetcher  */
    headers?: any
    /** Body of fetcher  */
    body?: any
    mode?: 'cors' | 'no-cors' | 'same-origin' | 'navigate'
    cache?: RequestCache
    referrerPolicy?: ReferrerPolicy
}

const checkConnectionTimeoutRef = {
    id: null,
} as { id: ReturnType<typeof setTimeout> | null }

let isRefreshingSession = false

const DEBUG_FETCH = ENV.VITE_DEBUG_FETCH === 'true'

export function useFetch() {
    const appStore = useContext(AppStoreContext)
    const { auth: authStore, setConnectionStatus } = appStore

    /**
     * Centralizes the operation to check the connection and prevents too many checks to be fired at the same time
     */
    const checkConnection = () => {
        if (checkConnectionTimeoutRef.id) {
            clearTimeout(checkConnectionTimeoutRef.id)
        }

        checkConnectionTimeoutRef.id = setTimeout(() => {
            checkInternetConnectivity(setConnectionStatus)
        }, 1)
    }

    useEffect(() => {
        return () => {
            if (checkConnectionTimeoutRef.id) {
                clearTimeout(checkConnectionTimeoutRef.id)
            }
        }
    }, [])

    /**
     * Fetcher function that centralizes the fetch operation and handles the session refresh
     *
     * @param url
     * @param params
     * @param avoidCookieCheck - see AuthStore.check() for more info
     * @param timeoutPeriod
     */
    const fetcher = async (
        url: string,
        params: IFetcher,
        avoidCookieCheck = false,
        timeoutPeriod = 10_000,
    ): Promise<boolean | string | JSON | Blob> => {
        // eslint-disable-next-line no-console
        DEBUG_FETCH && console.log('Fetcher called', url, params, avoidCookieCheck, timeoutPeriod)

        if (params?.requiredLogin && !authStore.loggedIn) return false

        const options = {
            method: params?.method ?? 'GET',
            credentials: params?.credentials ?? 'include',
            headers: params?.headers ?? {
                'Content-Type': 'application/json',
            },
            cache: params?.cache,
            mode: params?.mode ?? 'cors',
            referrerPolicy: params?.referrerPolicy ?? 'origin-when-cross-origin',
            signal: AbortSignal?.timeout?.(timeoutPeriod),
        } as RequestInit

        if (params?.body) {
            options.body = JSON.stringify(params.body)
        }

        const refreshSession = async (): Promise<boolean> => {
            try {
                const validation = await authStore.refreshToken()

                /**
                 * If the validation is undefined, it means that the server is down, so it doesn't do anything.
                 * Otherwise, if is true the cookie was refreshed and it retries the original request.
                 * If it couldn't refresh, destroys the local session
                 */
                if (validation === undefined) {
                    return false
                } else if (!validation) {
                    // If it couldn't refresh, destroys the local session
                    authStore.setAsLoggedOut()

                    return false
                } else {
                    // eslint-disable-next-line no-console
                    console.log('session refreshed')

                    return true
                }
            } catch (error) {
                console.error('Error refreshing session', error)

                return false
            }
        }

        const preRefreshSessionCheck = async (): Promise<boolean | string | JSON | Blob> => {
            if (isRefreshingSession) {
                // return a promise that will resolve in 500 ms with the original request
                return new Promise(resolve => {
                    setTimeout(() => {
                        resolve(fetcher(url, options, avoidCookieCheck, timeoutPeriod))
                    }, 500)
                })
            }

            // eslint-disable-next-line no-console
            console.log('cookie session expired, refreshing')
            isRefreshingSession = true

            if (!(await refreshSession())) {
                isRefreshingSession = false
                return false
            }

            return fetcher(url, params, avoidCookieCheck, timeoutPeriod)
        }

        const cookie = getCookie(SESSION_COOKIE_TTL)

        if (url.includes(ENV.VITE_CREDIT_SERVER_URL) && !avoidCookieCheck) {
            if (!cookie) {
                // Do not make calls to credit server if there is no cookie
                return false
            }

            if (isTTLCookieExpired(cookie)) {
                return preRefreshSessionCheck()
            }
        }

        // eslint-disable-next-line no-console
        DEBUG_FETCH && console.log('fetching', url, params)

        try {
            // eslint-disable-next-line no-console
            DEBUG_FETCH && console.log('Just before the fetch request', url)
            const response = await fetch(url, options)

            // eslint-disable-next-line no-console
            DEBUG_FETCH && console.log('response for', url, response)

            if (!response.ok) {
                if (response.status === 428) {
                    return preRefreshSessionCheck()
                } else if (response.status === 401) {
                    authStore.setAsLoggedOut()
                } else {
                    console.error(`Failed to fetch data for ${url}. Status: ${response.status}`)
                }

                return false
            }

            if (params.blobType) {
                return await response.blob()
            }

            try {
                return await response.json()
            } catch (error) {
                return await response.text()
            }
        } catch (error) {
            checkConnection()
            console.error('Error fetching data from', url, 'Error', error)
            return false
        }
    }

    return { fetcher }
}
