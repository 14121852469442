import { flow, getRoot, Instance, types } from 'mobx-state-tree'

import { FLPlugin, Identifier, IFLPlugin, IFLPluginDependency, InstallMode, InstallState, Label, PackagePlatform } from '@store/models/FLPlugin'
import { Category, ICategory } from '@store/models/Category'
import { ITier, ITierData, Tier, Tiers } from '@store/models/Tier'
import { InstallFilter, Sort, SortDirection } from '@constants/filters'
import { IPluginAPI, Config, IIdentifiable, IPluginCategoryData, INameAndId } from '@constants/api'
import ApiInstance from '@utils/api'
import { getInstalled } from '@src/interop'
import { sortTiersByOrder } from '@utils/misc'
import { LS_SORT_DIRECTION, LS_SORT_ORDER } from '@constants/storage'
import { computed } from 'mobx'
import { ITag, Tag } from '@store/models/Tag'
import { IRootStore } from '@store/RootStore'
import { IResponse } from '@src/classes/ApiClass'

const TIERS_ORDER = [Tiers.FREE, Tiers.PLUS, Tiers.PRO]
export const DEFAULT_SORT_DIRECTION = SortDirection.ASC

enum FilterTypes {
    INSTALL_STATUS,
    CATEGORY,
    TIER,
    TAG,
}

export const mockFeedStore = (properties = {}) => {
    return FeedStore.create({
        ...properties,
    })
}

const FeedStore = types
    .model('FeedStore', {
        plugins: types.map(FLPlugin),
        allCategories: types.optional(types.array(Category), []),
        allTiers: types.optional(types.array(Tier), []),
        allTags: types.optional(types.array(Tag), []),
        categoryTags: types.map(types.array(types.string)),

        // Array of ids that reference categories
        availableCategories: types.optional(types.array(types.string), []),

        // Filtering - NOTE turned all of these into optional to avoid TS errors
        searchTerm: types.optional(types.string, ''),
        selectedTier: types.optional(types.string, ''),
        selectedCategory: types.optional(types.string, ''),
        selectedTag: types.optional(types.string, ''),

        // null = All
        selectedInstallStatus: types.maybeNull(types.enumeration<InstallFilter>('InstallFilter', Object.values(InstallFilter))),

        sortBy: Sort.RECENT,
        sortDirection: DEFAULT_SORT_DIRECTION,
        installedPlugins: types.optional(types.frozen(), {}),
        verifyingPluginsQueue: types.optional(types.array(types.string), []),

        selectedPlugin: types.maybeNull(types.string),

        total: 0,
        page: 0,
        isLoading: false,
    })
    .views(self => {
        return {
            get root(): IRootStore {
                return getRoot(self)
            },
            get pluginsList() {
                return computed(() => {
                    return [...self.plugins.values()].filter((plugin: IFLPlugin) => {
                        return !plugin?.isHiddenFromCatalog
                    })
                }).get()
            },
            // We can't guarantee the order of the tiers, so let's sort them
            get sortedTiers() {
                return sortTiersByOrder(self.allTiers, TIERS_ORDER)
            },
            tierIndex(tierId: string) {
                return this.sortedTiers.findIndex((tier: ITier) => {
                    return tier.id === tierId
                })
            },
            // Extracted the search term logic into a getter to avoid recomputing it multiple times
            // and improve readability
            get searchTerms(): string[] {
                const terms = self.searchTerm.split(' ')

                return terms.filter((term: string) => {
                    return term.length > 1
                })
            },
            // Extracted the filtering by search logic into a getter to avoid recomputing it multiple times
            // and improve readability
            get pluginsFilteredBySearchTerm(): IFLPlugin[] {
                return this.pluginsList.filter((plugin: IFLPlugin) => {
                    // Cross reference the plugin tags and categories to the 'source of truth' all tags / categories for searching
                    const searchableTags = self.allTags.filter(({ id }: ITag) => {
                        return plugin.tagIds.includes(id)
                    })
                    const searchableCategories = self.allCategories.filter(({ id }: ICategory) => {
                        return plugin.categoryIds.includes(id)
                    })

                    return this.searchTerms.every((term: string) => {
                        const searchTerm = term.toLowerCase()
                        return (
                            plugin.name.toLowerCase().includes(searchTerm) ||
                            plugin.label.name.toLowerCase().includes(searchTerm) ||
                            searchableCategories.some(({ name }: ICategory) => {
                                return name.toLowerCase().includes(searchTerm)
                            }) ||
                            searchableTags.some(({ name }: ITag) => {
                                return name.toLowerCase().includes(searchTerm)
                            }) ||
                            plugin.descriptionLong.toLowerCase().includes(searchTerm) ||
                            plugin.descriptionShort.toLowerCase().includes(searchTerm)
                        )
                    })
                })
            },
            get filteredTiersIds() {
                const tierIndex = this.tierIndex(self.selectedTier!)
                const tierLimit = Math.min(tierIndex + 1, this.sortedTiers.length)
                const slicedTiers = this.sortedTiers.slice(0, tierLimit)
                return slicedTiers.map(({ id }: { id: string }) => {
                    return id
                })
            },
            sortFeedBy(a: IFLPlugin, b: IFLPlugin) {
                if (self.sortBy === Sort.ALPHABETICAL) {
                    return a.name.toLowerCase().localeCompare(b.name.toLowerCase())
                }

                if (self.sortBy === Sort.DEVELOPER) {
                    return a.label.name.toLowerCase().localeCompare(b.label.name.toLowerCase())
                }

                if (self.sortBy === Sort.RECENT) {
                    return b.releaseDateObj!.getTime() - a.releaseDateObj!.getTime()
                }

                if (self.sortBy === Sort.INSTALLED) {
                    if (!b.installDateObj && !a.installDateObj) return 0
                    if (!a.installDateObj) return 1
                    if (!b.installDateObj) return -1

                    return b.installDateObj.getTime() - a.installDateObj.getTime()
                }

                // Whatever the default sort from the API
                return 0
            },
            sortFeedByDirection(feed: IFLPlugin[]) {
                if (self.sortDirection === SortDirection.DESC) feed.reverse()
                return feed
            },
            doesCategoryExist(categoryId: string) {
                return self.allCategories.some(({ id }: ITag) => {
                    return id === categoryId
                })
            },
            doesTagExist(tagId: string) {
                return self.allTags.some(({ id }: ITag) => {
                    return id === tagId
                })
            },
            // Getter to check if a plugin passes all the individual filtering checks
            isPluginVisibleWithFiltering(plugin: IFLPlugin, filters: FilterTypes[] = []): boolean {
                const installStatusCheck =
                    filters.includes(FilterTypes.INSTALL_STATUS) && self.selectedInstallStatus
                        ? plugin.isFilteredByInstallStatus(self.selectedInstallStatus)
                        : true
                const categoryCheck =
                    filters.includes(FilterTypes.CATEGORY) && self.selectedCategory && this.doesCategoryExist(self.selectedCategory)
                        ? plugin.isFilteredByCategory(self.selectedCategory)
                        : true
                const tierCheck =
                    filters.includes(FilterTypes.TIER) && self.selectedTier && this.filteredTiersIds.length > 0
                        ? plugin.isFilteredByTiers(
                              this.filteredTiersIds.map((id: string) => {
                                  return String(id)
                              }),
                          )
                        : true
                const tagsCheck =
                    filters.includes(FilterTypes.TAG) && self.selectedTag && this.doesTagExist(self.selectedTag)
                        ? plugin.isFilteredByTag(self.selectedTag)
                        : true

                return installStatusCheck && categoryCheck && tierCheck && tagsCheck
            },
            // The main plugin's feed, with filtering and sorting
            get feed() {
                if (!self.selectedInstallStatus && !self.selectedCategory && !self.selectedTier && (!self.searchTerm || self.searchTerm === ''))
                    return this.pluginsList

                const sortedFeed = this.pluginsFilteredBySearchTerm
                    // Filter the plugins by all the selected filters
                    .filter((plugin: IFLPlugin) => {
                        return this.isPluginVisibleWithFiltering(plugin, [
                            FilterTypes.INSTALL_STATUS,
                            FilterTypes.CATEGORY,
                            FilterTypes.TIER,
                            FilterTypes.TAG,
                        ])
                    })
                    .sort(this.sortFeedBy)

                return this.sortFeedByDirection(sortedFeed)
            },
            isCategoryEnabled(categoryId: string) {
                return self.availableCategories.includes(categoryId)
            },
            get pluginsAvailableCount() {
                const installedAppIds = Object.keys(self.installedPlugins)

                return this.pluginsList.reduce((acc: number, plugin: IFLPlugin) => {
                    if (!installedAppIds.includes(plugin.appId)) {
                        acc++
                    }
                    return acc
                }, 0)
            },
            get pluginsInstalledCount() {
                const installedAppIds = Object.keys(self.installedPlugins)

                return this.pluginsList.reduce((acc: number, plugin: IFLPlugin) => {
                    if (installedAppIds.includes(plugin.appId)) {
                        acc++
                    }
                    return acc
                }, 0)
            },
            get pluginsUpdatableCount(): number {
                return this.pluginsList.reduce((acc: number, plugin: IFLPlugin) => {
                    if (plugin.isInstalled && plugin.isAvailableForUsersTier && plugin.canBeUpdated) {
                        acc++
                    }
                    return acc
                }, 0)
            },
            get activeQueueCount() {
                return this.pluginsList.reduce((acc: number, plugin: IFLPlugin) => {
                    if (
                        plugin.installStatus === InstallState.INSTALLING ||
                        plugin.installStatus === InstallState.INSTALLING_DEPENDENCIES ||
                        plugin.installStatus === InstallState.FINALIZING_INSTALL ||
                        plugin.installStatus === InstallState.REMOVING ||
                        plugin.installStatus === InstallState.FIXING_DEPENDENCIES
                    ) {
                        acc++
                    }
                    return acc
                }, 0)
            },
            get pluginsInstalling() {
                return this.pluginsList.filter((plugin: IFLPlugin) => {
                    return plugin.isInstalling
                })
            },
            get areUpdatesAvailable() {
                return this.pluginsUpdatableCount > 0
            },
            isPluginInstalled(id: string): boolean {
                if (!self.installedPlugins) return false

                return Object.keys(self.installedPlugins).includes(id)
            },
            getPluginPositionInFeed(pluginId: string) {
                return this.feed.findIndex(({ id }: IFLPlugin) => {
                    return id === pluginId
                })
            },
            previousPluginInFeed(pluginId: string) {
                const currentIndex = this.getPluginPositionInFeed(pluginId)
                // 0 or no match
                if (currentIndex < 0) return null

                return this.feed[currentIndex - 1] ?? null
            },
            nextPluginInFeed(pluginId: string) {
                const currentIndex = this.getPluginPositionInFeed(pluginId)

                if (currentIndex === undefined || currentIndex === null || currentIndex + 1 >= this.feed.length) return null

                return this.feed[currentIndex + 1] ?? null
            },
            isTagSelected(tagId: string | null) {
                return self.selectedTag === tagId
            },
            get allPluginTags() {
                const allTags = this.pluginsFilteredBySearchTerm
                    // Filter the plugins by selected filters and if they have any tags
                    .filter((plugin: IFLPlugin) => {
                        return (
                            this.isPluginVisibleWithFiltering(plugin, [FilterTypes.INSTALL_STATUS, FilterTypes.CATEGORY, FilterTypes.TIER]) &&
                            plugin.tagIds?.length > 0
                        )
                    })
                    // Return only the plugin's tags
                    .map(({ tagIds }: IFLPlugin) => {
                        return tagIds ?? []
                    })
                    // Flatten the tags into a single array
                    .flat()

                // Return a unique array of tags that exist on VISIBLE plugins
                return [...new Set(allTags)]
            },
            get availableTags() {
                const tagsPerCategory = self.categoryTags.get(self.selectedCategory)

                // No tags for this category or category is invalid
                if (!tagsPerCategory || tagsPerCategory.length === 0) return []

                // Filter all tags by the selected category and being assigned to a visible plugin
                return self.allTags.filter(({ id }: ITag) => {
                    return tagsPerCategory.includes(id) && this.allPluginTags.includes(id)
                })
            },
            get visibleCategories() {
                return [...self.categoryTags.keys()]
                    .map((id: string) => {
                        return self.allCategories.find(({ id: categoryId }: ICategory) => {
                            return categoryId === id
                        })
                    })
                    ?.filter(Boolean)
            },
            get isViewingCategoryAll() {
                return !self.selectedCategory
            },
        }
    })
    .actions(self => {
        return {
            setSortBy: (sort: Sort) => {
                self.sortBy = sort

                // Store the sort in localStorage
                localStorage.setItem(LS_SORT_ORDER, sort)
            },
            setSortDirection: (direction: SortDirection) => {
                self.sortDirection = direction

                // Store the sort direction in localStorage
                localStorage.setItem(LS_SORT_DIRECTION, direction)
            },
            setCategoryTags(id: string, tags: string[]) {
                self.categoryTags.set(id, tags)
            },
            setIsLoading: (isLoading: boolean) => {
                self.isLoading = isLoading
            },
            addPluginToList: (plugin: IFLPlugin, force = false) => {
                if (self.plugins.has(plugin.appId) && !force) return
                self.plugins.set(plugin.appId, plugin)
            },
            setCategories: (categories: any) => {
                self.allCategories = categories
            },
            setTiers: (tiers: any) => {
                self.allTiers = tiers
            },
            setTags: (tags: any) => {
                self.allTags = tags
            },
            setAvailableCategories: (categories: any) => {
                self.availableCategories = categories
            },
            setActiveCategory: (category: string | null) => {
                self.selectedCategory = category ?? ''
            },
            setActiveTier: (tier: string | null) => {
                self.selectedTier = tier ?? ''
            },
            setActiveInstallStatus: (status: InstallFilter | null) => {
                self.selectedInstallStatus = status
            },
            setTotal: (total = 0) => {
                self.total = total
            },
            setPage: (page = 0) => {
                self.page = page
            },
            setSearchTerm(term: string) {
                self.searchTerm = term
            },
            clearPlugins: () => {
                self.plugins.clear()
            },
            setActiveTag: (value: string | null) => {
                self.selectedTag = value ?? ''
            },
            /**
             * Checks dependencies for all plugins.
             */
            checkAllInstalledDependencies() {
                const installedAppIds = Object.keys(self.installedPlugins)

                installedAppIds.forEach((pluginId: any) => {
                    const plugin = self.plugins.get(pluginId)

                    if (!plugin) {
                        console.warn('Plugin not found in the catalog:', pluginId)
                        return
                    }

                    if (plugin.isRemoving) return

                    plugin.checkDependencies()
                })
            },
        }
    })
    .actions(self => {
        return {
            toggleSortDirection: () => {
                const direction = self.sortDirection === SortDirection.DESC ? SortDirection.ASC : SortDirection.DESC
                self.setSortDirection(direction)
            },
        }
    })
    .actions(self => {
        return {
            afterCreate: () => {
                const lastSort = localStorage.getItem(LS_SORT_ORDER)
                if (lastSort && Object.values(Sort).includes(lastSort as Sort)) {
                    self.setSortBy(lastSort as Sort)
                }

                const lastDirection = localStorage.getItem(LS_SORT_DIRECTION)

                if (lastDirection && Object.values(SortDirection).includes(lastDirection as SortDirection)) {
                    self.setSortDirection(lastDirection as SortDirection)
                }
            },
            setSortAndDirection: (sort: Sort) => {
                if (self.sortBy === sort) {
                    // Same sort, toggle direction
                    self.toggleSortDirection()
                } else {
                    // Different sort, default direction
                    self.setSortDirection(DEFAULT_SORT_DIRECTION)
                }

                self.setSortBy(sort)
            },
            enqueueVerifyingPlugin(pluginId: string) {
                // Isn't a simple "push" enough?
                if (!self.verifyingPluginsQueue.length) {
                    self.verifyingPluginsQueue.replace([pluginId])
                    return
                }
                self.verifyingPluginsQueue.push(pluginId)
            },
            dequeueVerifyingPlugin(pluginId: string) {
                const index = self.verifyingPluginsQueue.indexOf(pluginId)

                if (index !== -1) {
                    self.verifyingPluginsQueue.splice(index, 1)
                }

                if (self.verifyingPluginsQueue.length === 0) {
                    self.checkAllInstalledDependencies()
                }
            },
            getInstalledPlugins: flow(function* () {
                const plugins = yield getInstalled()

                self.installedPlugins = plugins ?? {}

                const installedAppIds = Object.keys(self.installedPlugins)

                // Set all available plugins
                self.pluginsList.forEach((plugin: any) => {
                    if (plugin.installStatus !== InstallState.INIT || installedAppIds.includes(plugin.appId)) return

                    plugin.setStatus(InstallState.AVAILABLE)
                })

                // Check the install status for each of the installed plugins and set the currently installed version
                installedAppIds.forEach((pluginId: string) => {
                    const plugin: IFLPlugin | undefined = self.plugins.get(pluginId)

                    if (!plugin) {
                        // eslint-disable-next-line no-console
                        console.log('Plugin not found in the catalog:', pluginId)
                        return
                    }

                    if (plugin.installStatus === InstallState.REMOVING) return

                    plugin.checkInstallStatus()
                    plugin.setInstalledVersion(self.installedPlugins[plugin.appId] ?? null)
                })
            }),
            removeOrphanedPackages: (appIdsToCheck: string[]) => {
                const installedAppIds = Object.keys(self.installedPlugins)

                appIdsToCheck.forEach((pluginId: any) => {
                    // only check installed plugins
                    if (!installedAppIds.includes(pluginId)) return

                    const plugin = self.plugins.get(pluginId)

                    if (!plugin) {
                        // eslint-disable-next-line no-console
                        console.log('Plugin not found in the catalog:', pluginId)
                        return
                    }

                    if (!plugin.isHiddenFromCatalog) return

                    // check all hidden packages, if no plugin depends on them, remove them
                    const dependencies = installedAppIds.filter((appId: string) => {
                        const pluging: IFLPlugin | undefined = self.plugins.get(appId)
                        if (!pluging) return false

                        return pluging.platformPackage?.dependencies.find(dep => {
                            if (!dep) return false
                            // Cast after null-check, telling TS: "Yes, I know it's an IFLPluginDependency."
                            return (dep as IFLPluginDependency).appId === pluginId
                        })
                    })

                    if (dependencies.length === 0) {
                        plugin.uninstallPlugin()
                    }
                })
            },
            isCategoryEnabled(categoryId: string) {
                return self.availableCategories.includes(categoryId)
            },
            setSelectedPlugin(pluginId: string | null) {
                self.selectedPlugin = pluginId
            },
        }
    })
    .actions(self => {
        return {
            fetchPlugins: flow(function* (page = 0, limit = Config.searchLimit) {
                if (self.isLoading) return

                self.setIsLoading(true)

                try {
                    const result = yield ApiInstance.content.searchPlugins({ page, limit })

                    const { total, categories, plugins } = result

                    if (categories) {
                        self.setAvailableCategories(categories)
                    }

                    if (plugins) {
                        plugins.forEach(({ label, winVersion, macVersion, categories = [], tiers = [], ...pluginData }: IPluginAPI) => {
                            const pluginCategories = categories.map((category: Pick<IIdentifiable, 'id' | 'name'>) => {
                                return Identifier.create(category)
                            })
                            const pluginTiers = tiers.map((tier: ITierData) => {
                                return Tier.create(tier)
                            })

                            const plugin = FLPlugin.create({
                                label: Label.create(label),
                                ...(winVersion ? { winVersion: PackagePlatform.create(winVersion) } : {}),
                                ...(macVersion ? { macVersion: PackagePlatform.create(macVersion) } : {}),
                                categories: pluginCategories,
                                tiers: pluginTiers,
                                assetUrl: pluginData.assetUrl ?? '',
                                ...pluginData,
                            })

                            self.addPluginToList(plugin)
                        })
                    }

                    self.setTotal(total ?? 0)
                    self.setIsLoading(false)
                    yield self.getInstalledPlugins()
                } catch (e: any) {
                    console.error('Error retrieving plugins:', e.message)
                }

                // Refresh the plugins every 24 hours
                setTimeout(
                    () => {
                        // @ts-ignore
                        this.fetchPlugins(page, limit)
                    },
                    60 * 60 * 24 * 1000,
                ) // 24 hours
            }),
            resetSelectedTagIfDoesNotExist() {
                const doesSelectedTagExist = self.availableTags.some(({ id }: ITag) => {
                    return id === self.selectedTag
                })

                if (doesSelectedTagExist) {
                    return
                }

                self.setActiveTag(null)
            },
            updateAllPlugins() {
                if (!self.areUpdatesAvailable || self.selectedInstallStatus !== InstallFilter.UPDATES) return

                self.feed.forEach((plugin: IFLPlugin) => {
                    if (!plugin.isVersionOutdated && plugin.outdatedDependencies.length === 0) return
                    plugin.installPlugin(InstallMode.FULL_INSTALL)
                })
            },
            filterByNewBadge() {
                if (self.sortBy === Sort.RECENT && self.sortDirection === SortDirection.ASC) return

                self.setSortBy(Sort.RECENT)
                self.setSortDirection(SortDirection.ASC)
            },
        }
    })
    .actions(self => {
        return {
            // Using an init function to make sure the fetcher hook is in place
            getCategoriesTiersAndTags: flow(function* () {
                // Fetch and populate the categories
                yield ApiInstance.content
                    .getAllCategories()
                    .then(response => {
                        if (!response || !Array.isArray(response)) return
                        const categories = response.map((r: any) => {
                            return Category.create(r)
                        })
                        self.setCategories(categories)
                    })
                    .catch(error => {
                        console.error('Error retrieving categories:', error.message)
                    })

                // Fetch and populate the tiers
                yield ApiInstance.content
                    .getAllTiers()
                    .then(response => {
                        if (!response || !Array.isArray(response)) return
                        const tiers = response.map((r: any) => {
                            return Tier.create(r)
                        })
                        self.setTiers(tiers)
                    })
                    .catch(error => {
                        console.error('Error retrieving tiers:', error.message)
                    })

                // Fetch and populate all tags
                yield ApiInstance.content
                    .getTags()
                    .then((response: IResponse) => {
                        if (!response || !Array.isArray(response)) return

                        const allTags = response.map(({ uid, properties }: any) => {
                            return Tag.create({
                                id: uid,
                                name: properties.name,
                            })
                        })
                        self.setTags(allTags)
                    })
                    .catch(error => {
                        console.error('Error retrieving tags:', error.message)
                    })

                // Fetch tags menu, grouped by category
                yield ApiInstance.content
                    .getCategoryTags()
                    .then((response: IPluginCategoryData[]) => {
                        if (!response) return

                        response.forEach(({ category, tags }: IPluginCategoryData) => {
                            if (!self.doesCategoryExist(category.id)) return

                            // Filter menu tags by the visible tags
                            const tagIds = tags
                                .filter((tag: INameAndId) => {
                                    return self.doesTagExist(tag.id)
                                })
                                .map(({ id }) => {
                                    return id
                                })

                            // Map of category id => array of associated tag ids
                            self.setCategoryTags(category.id, tagIds)
                        })
                    })
                    .catch(error => {
                        console.error('Error retrieving tags:', error.message)
                    })

                // Automatically refresh the categories, tiers and tags every 24 hours
                setTimeout(
                    () => {
                        // @ts-ignore
                        this.getCategoriesTiersAndTags()
                    },
                    60 * 60 * 24 * 1000,
                ) // 24 hours
            }),
        }
    })

export interface IFeedStore extends Instance<typeof FeedStore> {}

export default FeedStore
