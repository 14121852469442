import { IFLPlugin, InstallMode, InstallState } from '@store/models/FLPlugin'
import { useCallback, useEffect, useMemo, useState } from 'react'

import { CloseIcon } from '@components/icons/CloseIcon'
import { COLORS } from '@styles/Colors'
import { observer } from 'mobx-react'
import { ModalType } from '@constants/types'

import { useAppStoreContext } from '@store/RootStore'

type IInstallStatusButtonProps = {
    plugin: IFLPlugin
    canBeUninstalled?: boolean
    isHovered?: boolean
    uninstallPlugin: () => void
}

export const InstallStatusButton = ({ plugin, canBeUninstalled = false, isHovered = false, uninstallPlugin }: IInstallStatusButtonProps) => {
    const { modal: modalStore, feed: feedStore } = useAppStoreContext()
    const {
        appId,
        requiresMarketingOptIn,
        requiresDependenciesForInstall,
        installStatus,
        installPlugin,
        cancelInstallPlugin,
        isVersionOutdated: canBeUpdated,
        areDependenciesOutdated: canDependenciesBeUpdated,
        isLicenseRequired,
        fixInstall,
    } = plugin

    const [infoIconElement, setInfoIconElement] = useState<null | HTMLDivElement>(null)
    const [infoIconHovered, setInfoIconHovered] = useState(false)

    const infoIconRef = useCallback((node: null | HTMLDivElement) => {
        if (node === null) {
            setInfoIconElement(null)
            return
        }

        setInfoIconElement(node)
    }, [])

    useEffect(() => {
        if (!infoIconElement) return

        let timeOutRef: number

        const mouseEnter = () => {
            timeOutRef = window.setTimeout(() => {
                setInfoIconHovered(true)
            }, 200)
        }

        const mouseLeave = () => {
            clearTimeout(timeOutRef)
            setInfoIconHovered(false)
        }

        infoIconElement.addEventListener('mouseenter', mouseEnter)
        infoIconElement.addEventListener('mouseleave', mouseLeave)

        return () => {
            infoIconElement.removeEventListener('mouseenter', mouseEnter)
            infoIconElement.removeEventListener('mouseleave', mouseLeave)
        }
    }, [infoIconElement, infoIconHovered])

    const onClick = useCallback(() => {
        switch (installStatus) {
            case InstallState.AVAILABLE:
            case InstallState.ERRORED_ON_INSTALL:
                if (isLicenseRequired) {
                    feedStore.setSelectedPlugin(null)
                    modalStore.showModal(ModalType.RETRIEVE_LICENCE_CODE, { pluginAppId: appId })
                    return
                }
                if (requiresMarketingOptIn) {
                    feedStore.setSelectedPlugin(null)
                    modalStore.showModal(ModalType.THIRD_PARTY_OPT_IN, { pluginAppId: appId })
                    return
                }
                if (requiresDependenciesForInstall) {
                    modalStore.showModal(ModalType.REQUIRES_DEPENDENCIES_INSTALL, { pluginAppId: appId })
                    return
                }

                installPlugin(InstallMode.FULL_INSTALL, installStatus === InstallState.ERRORED_ON_INSTALL)
                break
            case InstallState.ERRORED_ON_UNINSTALL:
                uninstallPlugin()
                break
            case InstallState.BROKEN:
                fixInstall()
                break
            case InstallState.MISSING_DEPENDENCIES:
                installPlugin(InstallMode.ONLY_MISSING_DEPENDENCIES)
                break
            case InstallState.INSTALLING:
            case InstallState.INSTALLING_DEPENDENCIES:
            case InstallState.INSTALLING_AS_DEPENDENCY:
                cancelInstallPlugin()
                break
            case InstallState.FINALIZING_INSTALL:
            case InstallState.FINALIZING_INSTALL_AS_DEPENDENCY:
                break
            case InstallState.INSTALLED:
                if (canBeUpdated) {
                    installPlugin(InstallMode.FULL_INSTALL)
                    return
                } else if (canDependenciesBeUpdated) {
                    installPlugin(InstallMode.UPDATE_DEPENDENCIES)
                    return
                }
                break
            case InstallState.REMOVING:
                break
            default:
                break
        }
    }, [appId, installStatus, canBeUpdated, canDependenciesBeUpdated, isLicenseRequired, requiresMarketingOptIn, requiresDependenciesForInstall])

    const button = useMemo(() => {
        const installingObj = {
            title: isHovered ? 'Cancel' : 'Installing',
            className: `bg-flp-button-bg-highlight border-transparent text-flp-button-text gap-[8px] ${
                isHovered ? 'cursor-hover' : 'cursor-default'
            }`,
        }

        return {
            [InstallState.AVAILABLE]: {
                // TODO: "Retry" and "Fix things" buttons
                title: isLicenseRequired ? 'Get Code' : 'Install',
                // Licence code install path or marketing opt in install path
                ...(isLicenseRequired
                    ? {
                          className:
                              'bg-flp-button-free-bg border-flp-button-free-bg-highlight text-flp-button-free-text fill-flp-button-free-text hover:bg-flp-button-free-bg-highlight',
                      }
                    : {}),

                // Standard install path
                ...(!isLicenseRequired
                    ? {
                          className:
                              'bg-flp-button-bg text-flp-button-text border-bg-flp-button-bg hover:bg-flp-button-bg-highlight hover:border-transparent',
                      }
                    : {}),
            },

            /* Installing states */
            [InstallState.INSTALLING]: installingObj,
            [InstallState.INSTALLING_DEPENDENCIES]: installingObj,
            [InstallState.INSTALLING_AS_DEPENDENCY]: installingObj,
            /* Installing states */

            [InstallState.FINALIZING_INSTALL]: {
                className: 'bg-flp-button-bg-highlight border-transparent text-flp-button-text gap-[8px]',
                title: 'Installing',
            },
            [InstallState.FINALIZING_INSTALL_AS_DEPENDENCY]: {
                className: 'bg-flp-button-bg-highlight border-transparent text-flp-button-text gap-[8px]',
                title: 'Installing',
            },
            [InstallState.FIXING_DEPENDENCIES]: {
                title: 'Fixing',
                className: 'bg-flp-button-bg-highlight border-transparent text-flp-button-text gap-8px cursor-default',
            },
            [InstallState.UPDATING_DEPENDENCIES]: {
                title: 'Installing',
                className: 'bg-flp-button-bg-highlight border-transparent text-flp-button-text gap-8px cursor-default',
            },
            [InstallState.INSTALLED]: {
                title: canBeUpdated || canDependenciesBeUpdated ? 'Update' : 'Installed',
                className:
                    canBeUpdated || canDependenciesBeUpdated
                        ? 'bg-flp-button-bg text-flp-button-text border-bg-flp-button-bg hover:bg-flp-button-bg-highlight hover:border-transparent'
                        : 'bg-flp-button-grey-background text-flp-button-grey-text border-flp-button-grey-background hover:bg-flp-button-grey-background-highlight hover:border-transparent',
            },
            [InstallState.REMOVING]: {
                title: 'Removing',
                className:
                    'bg-flp-button-yellow-background text-flp-button-yellow-text border-flp-button-yellow-background hover:bg-flp-button-yellow-background-highlight hover:border-transparent',
            },
            [InstallState.ERRORED_ON_INSTALL]: {
                title: 'Retry',
                className: 'bg-flp-button-bg text-flp-button-text border-bg-flp-button-bg hover:bg-flp-button-bg-highlight hover:border-transparent',
            },
            [InstallState.ERRORED_ON_UNINSTALL]: {
                title: 'Retry',
                className: 'bg-flp-button-bg text-flp-button-text border-bg-flp-button-bg hover:bg-flp-button-bg-highlight hover:border-transparent',
            },
            [InstallState.BROKEN]: {
                title: isHovered ? 'Fix' : 'Broken',
                className: isHovered
                    ? 'bg-flp-button-yellow-background border-flp-button-yellow-background-highlight text-flp-button-yellow-text hover:bg-flp-button-yellow-background-highlight hover:border-transparent'
                    : 'bg-flp-button-grey-background text-flp-button-grey-text border-flp-button-grey-background',
            },
            [InstallState.MISSING_DEPENDENCIES]: {
                title: 'Fix',
                className:
                    'bg-flp-button-yellow-background border-flp-button-yellow-background-highlight text-flp-button-yellow-text hover:bg-flp-button-yellow-background-highlight hover:border-transparent',
            },
            [InstallState.VERIFYING_INSTALL]: {
                title: 'Verifying...',
                className: 'bg-flp-button-grey-background text-flp-button-grey-text border-flp-button-grey-background !cursor-default',
            },
            [InstallState.INIT]: {
                title: '',
                className: 'bg-flp-button-grey-background text-flp-button-grey-text border-flp-button-grey-background !cursor-default',
            },
        }
    }, [appId, isHovered, canBeUpdated, canDependenciesBeUpdated, isLicenseRequired, requiresMarketingOptIn])
    const { title, className } = useMemo(() => {
        return button[installStatus as InstallState]
    }, [appId, installStatus, isHovered, canBeUpdated, canDependenciesBeUpdated])

    if (installStatus === InstallState.INIT) return

    if (canBeUninstalled && isHovered) {
        return (
            <div
                className="flp-button group bg-flp-button-yellow-background border-flp-button-yellow-background-highlight text-flp-button-yellow-text hover:bg-flp-button-yellow-background-highlight hover:border-transparent"
                onClick={uninstallPlugin}>
                Uninstall
            </div>
        )
    }

    return (
        <div className={`flp-button relative ${className}`} onClick={onClick}>
            {[InstallState.AVAILABLE, InstallState.ERRORED_ON_INSTALL].includes(installStatus as InstallState) && requiresMarketingOptIn && (
                <div ref={infoIconRef} className="absolute -left-1 top-4px z-99">
                    <div className="group/icon flex items-center border border-flp-button-border overflow-hidden rounded-2xl h-20px w-20px bg-[#0a1b22] text-center cursor-pointer mr-2 absolute right-0 pt-1px hover:w-165px z-99 transition-width duration-150">
                        <span className="w-20px text-center opacity-1 group-hover/icon:opacity-0 transition-opacity duration-200">i</span>
                        <span className="absolute left-5 opacity-0 group-hover/icon:opacity-100 transition-opacity duration-200 delay-75">
                            Requires email opt-in
                        </span>
                    </div>
                </div>
            )}
            {title}
            {[InstallState.INSTALLING, InstallState.INSTALLING_DEPENDENCIES, InstallState.INSTALLING_AS_DEPENDENCY].includes(
                installStatus as InstallState,
            ) &&
                isHovered && (
                    <CloseIcon
                        className="flp-icon h-13px w-13px cursor-pointer group-hover:block"
                        style={{ '--icon-stroke-color': COLORS['flp-primary'], '--icon-stroke-hover-color': COLORS['flp-primary'] as string }}
                    />
                )}
        </div>
    )
}

export default observer(InstallStatusButton)
