import { types } from 'mobx-state-tree'

import { ModalType } from '@constants/types'

const ModalStore = types
    .model('ModalStore', {
        activeModal: types.maybeNull(types.enumeration(Object.values(ModalType))),
        allowDismiss: types.optional(types.boolean, true),
        data: types.maybeNull(types.frozen()),
    })
    .actions(self => {
        return {
            showModal(type: ModalType | null, data = {}, allowDismiss = true) {
                self.activeModal = type
                self.allowDismiss = allowDismiss
                self.data = data ?? null
            },
            closeModal() {
                self.activeModal = null
                self.allowDismiss = true
                self.data = null
            },
        }
    })

export default ModalStore
